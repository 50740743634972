import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import numbro from "numbro";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
// theme
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";
import { useDispatch } from "react-redux";
import theme from "../../theme-admin";
import Table from "../table";
import TableFooter from "../table-footer";
import InvoicingReportToolbar from "../../containers/invoicing-report/invoicing-report-toolbar";
import TableDataDownload from "../table-data-download";
import { formatDate, formatDateUtc } from "../../utils/dateUtils";
import { setDashboardTitle } from "../../redux/actions/misc-actions";
import { resetAllAdSpaces } from "../../redux/actions/adspaces-actions";
import { resetAllSpaces } from "../../redux/actions/spaces-owner-actions";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    height: "100%",
    "& .MuiTableCell-head > span": {
      justifyContent: "center",
    },
  },
  insightsContainer: {
    width: 300,
    padding: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "&:nth-child(odd)": {
      marginTop: theme.spacing(6),
    },
    "&:nth-child(even)": {
      marginTop: theme.spacing(2),
    },
  },
}));

const INITIAL_DISPLAY = {
  id: false,
  number: false,
  booking_id: false,
  date: true,
  doc_type: true,
  payment: true,
  asset_name: false,
  space_name: false,
  brand_name: false,
  company: false,
  start: true,
  end: false,
  duration: true,
  price: true,
  tax_id: false,
  booking_category: false,
};

function InvoicingReport({
  invoices = [],
  pagination,
  onChangePage,
  status,
  downloadTableData,
  onTableChange,
  onRowClick = () => {},
  flagInvoiceTransactions,
}) {
  const { i18n } = useLingui();
  const classes = useStyles(theme);
  const dispatch = useDispatch();

  const [displayColumn, setDisplayColumn] = useState(INITIAL_DISPLAY);
  const [openDownloadModal, setOpenDownloadModal] = useState(false);

  const handleShowHideColumn = (col, action) => {
    const filterColumsFlag = { ...displayColumn };
    if (flagInvoiceTransactions) {
      delete filterColumsFlag.payment;
    }
    if (action === "add") {
      setDisplayColumn({ ...filterColumsFlag, [col]: true });
      localStorage.removeItem("invoicesColumns");
      localStorage.setItem("invoicesColumns", JSON.stringify({ ...filterColumsFlag, [col]: true }));
    } else if (action === "remove") {
      setDisplayColumn({ ...filterColumsFlag, [col]: false });
      localStorage.removeItem("invoicesColumns");
      localStorage.setItem("invoicesColumns", JSON.stringify({ ...filterColumsFlag, [col]: false }));
    }
  };

  const handleOpenDownloadTable = () => {
    setOpenDownloadModal(true);
    return false;
  };

  const handleTableData = () => {
    const data = invoices.map((i) => {
      return {
        id: i.id,
        number: i.number,
        booking_id: i.booking_id,
        doc_type: i.doc_type === "invoice" ? i18n._(t`Factura`) : i18n._(t`Abono`),
        asset_name: i.asset_name,
        date: i.date ? formatDateUtc(i.date, "DD/MM/YYYY") : "-",
        payment: i.payment
          ? formatDate(i.payment, "DD/MM/YYYY")
          : i.doc_type === "invoice"
          ? "-"
          : i18n._(t`No Aplica`),
        space_id: i.space_id,
        space_name: i.space_name,
        brand_name: i.brand_name,
        company: i.company_name,
        start: formatDate(i.start, "DD/MM/YYYY"),
        end: formatDate(i.end, "DD/MM/YYYY"),
        // bug backend.
        duration: i.duration,
        confirmed_at: i.confirmed_at,
        price: numbro(parseFloat(i.price)).formatCurrency(),
        tax_id: i.tax_id,
        booking_category: i18n._(t`${i.booking_category_slug}`),
      };
    });

    return data;
  };

  const columnOptions = {
    setCellProps: (value) => {
      return {
        style: {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          whiteSpace: "nowrap",
          textAlign: "center",
        },
      };
    },
    setCellHeaderProps: (value) => {
      return {
        style: {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          whiteSpace: "nowrap",
          textAlign: "center",
        },
      };
    },
  };

  useEffect(() => {
    dispatch(setDashboardTitle(t`Facturación`));
    dispatch(resetAllAdSpaces());
    dispatch(resetAllSpaces());
    const columns = localStorage.getItem("invoicesColumns");
    if (columns) {
      setDisplayColumn(JSON.parse(columns));
    } else {
      localStorage.setItem("invoicesColumns", JSON.stringify(displayColumn));
    }
  }, []);

  const columns = [
    {
      label: `${i18n._(t`Número de Factura`)}`,
      name: "number",
      options: { display: displayColumn.number, sort: false, ...columnOptions },
    },
    {
      label: `${i18n._(t`Factura  ID`)}`,
      name: "id",
      options: { display: displayColumn.id, sort: false, ...columnOptions },
    },
    {
      label: `${i18n._(t`Booking ID`)}`,
      name: "booking_id",
      options: { display: displayColumn.booking_id, sort: false, ...columnOptions },
    },
    {
      label: `${i18n._(t`Tipo Doc`)}`,
      name: "doc_type",
      options: { display: displayColumn.doc_type, sort: false, ...columnOptions },
    },
    {
      label: `${i18n._(t`Fecha`)}`,
      name: "date",
      options: { display: displayColumn.date, sort: true, ...columnOptions },
    },
    {
      label: `${i18n._(t`Pagada`)}`,
      name: "payment",
      options: { display: displayColumn.payment, sort: true, ...columnOptions },
    },
    {
      label: `${i18n._(t`Centro Comercial`)}`,
      name: "asset_name",
      options: { display: displayColumn.asset_name, sort: true, ...columnOptions },
    },
    {
      label: `${i18n._(t`Marca`)}`,
      name: "brand_name",
      options: { display: displayColumn.brand_name, sort: true, ...columnOptions },
    },
    {
      label: `${i18n._(t`Empresa`)}`,
      name: "company",
      options: { display: displayColumn.company, sort: true, ...columnOptions },
    },
    {
      label: `${i18n._(t`Confirmada`)}`,
      name: "confirmed_at",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return <Box>{invoices[dataIndex].confirmed_at !== null ? i18n._(t`Si`) : i18n._(t`No`)}</Box>;
        },
        display: displayColumn.duration,
        sort: true,
        ...columnOptions,
      },
    },
    {
      label: `${i18n._(t`Importe`)}`,
      name: "price",
      options: { display: displayColumn.price, sort: true, ...columnOptions },
    },
    {
      label: i18n._(t`Tax ID`),
      name: "tax_id",
      options: { display: displayColumn.tax_id, sort: true, ...columnOptions },
    },
    {
      label: i18n._(t`Categoría`),
      name: "booking_category",
      options: { display: displayColumn.booking_category, sort: true, ...columnOptions },
    },
  ];

  const columnsFilterByFlagInvoiceTransactions = flagInvoiceTransactions
    ? columns.filter((col) => col.name !== "payment")
    : columns;

  return (
    <Box className={classes.container} pl={2} pr={2}>
      <TableDataDownload
        open={openDownloadModal}
        onClose={() => setOpenDownloadModal(false)}
        onDownload={downloadTableData}
      />
      <Box position="relative" width="100%">
        <Table
          data={handleTableData()}
          columns={columnsFilterByFlagInvoiceTransactions}
          isLoading={status === "loading"}
          onDownload={handleOpenDownloadTable}
          options={{
            fixedSelectColumn: true,
            responsive: "simple",
            onTableChange,
            onRowClick,
            customToolbar: () => {
              return <InvoicingReportToolbar />;
            },
            onViewColumnsChange: handleShowHideColumn,
            customFooter: () => {
              return <TableFooter pagination={pagination} onChangePage={onChangePage} canCreate={false} />;
            },
          }}
        />
      </Box>
      {/* <Box>
        <Paper flex={"none"} className={classes.insightsContainer}>
          <TotalInvoicingGraph />
        </Paper>
         <Paper flex={"none"} className={classes.insightsContainer}>
          <TopSpacesInvoicing />
        </Paper> 
      </Box> */}
    </Box>
  );
}

InvoicingReport.propTypes = {
  invoices: PropTypes.array.isRequired,
  status: PropTypes.string.isRequired,
};

export default InvoicingReport;

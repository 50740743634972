import React, { useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useLingui } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import theme from "../../theme";
import WizardFooter from "./footer";

const useStyles = makeStyles((th) => ({
  input: {
    borderRadius: 0,
    border: th.borders[1],
    paddingTop: th.spacing(0),
    paddingBottom: th.spacing(0),
    paddingLeft: th.spacing(2),
  },
  divider: {
    borderBottom: th.borders[1],
  },
}));

const StepPriceComponent = ({ onSubmit, onBack, bookingCategories, bookingCategoriesStatus }) => {
  const classes = useStyles(theme);

  const { i18n } = useLingui();

  const [category, setCategory] = useState(-1);
  const canSubmit = bookingCategoriesStatus !== "loading" && category !== -1;

  const handleSelectCategory = ({ target }) => {
    setCategory(target.value);
  };

  const handleSubmit = () => {
    onSubmit(category);
  };

  return (
    <>
      <Box mb={1}>
        <Typography variant="subtitle1">
          <Trans>Seleccione la categoría de reserva*</Trans>
        </Typography>
      </Box>
      <Select
        value={category}
        onChange={handleSelectCategory}
        style={{ width: "220px", height: "34px" }}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: { vertical: "bottom", horizontal: "left" },
          transformOrigin: { vertical: "top", horizontal: "left" },
        }}
        label="SelectTest"
        className={classes.input}
        id="grouped-select"
      >
        {bookingCategories.map((b) => (
          <MenuItem key={b.id} value={b.id}>
            {i18n._(t`${b.slug}`)}
          </MenuItem>
        ))}
      </Select>
      <Box className={classes.divider} mt={2} />
      <WizardFooter step={3} onNext={handleSubmit} canSubmit={canSubmit} onBack={onBack} />
    </>
  );
};

export default StepPriceComponent;

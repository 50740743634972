import React, { useEffect, useState } from "react";
import StepUsesComponent from "../../components/create-booking-wizard/step-4-uses";
import api from "../../api";

function StepUsesContainer({ updateBooking, booking, onBack }) {
  const [bookingCategories, setBookingCategories] = useState([]);
  const [bookingCategoriesStatus, setBookingCategoriesStatus] = useState("initial");

  const getBookingCategories = async () => {
    setBookingCategoriesStatus("loading");
    const res = await api.getBookingCategories();
    if (res.status === 200) {
      setBookingCategoriesStatus("success");
      setBookingCategories(res.data.payload.data);
    } else {
      setBookingCategoriesStatus("error");
    }
  };

  const handleSubmit = (id) =>
    updateBooking({
      ...booking,
      bookingCategory: id,
    });

  useEffect(() => {
    getBookingCategories();
  }, []);

  return (
    <StepUsesComponent
      onBack={onBack}
      onSubmit={handleSubmit}
      bookingCategories={bookingCategories}
      bookingCategoriesStatus={bookingCategoriesStatus}
    />
  );
}

export default StepUsesContainer;
